import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CareerProvider extends HttpRequest {
  getCareers (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/careers', query)
  }

  count (query) {
    this.setHeader(getAuthToken())
    return this.get('/careers/count', query)
  }

  createCareer (payload) {
    this.setHeader(getAuthToken())
    return this.post('/careers', payload)
  }

  editCareer (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/careers/${id}`, payload)
  }

  updateState (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/careers/${id}/state`, payload)
  }

  deleteCareer (params) {
    this.setHeader(getAuthToken())
    return this.delete(`/careers/${params}`)
  }
}

export default CareerProvider
