<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header pl-2">Careers</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <v-row
          justify="end"
          class="head-search ma-0">
          <v-col
            cols="10"
            class="d-flex justify-end align-center">
            <v-select
              v-model="selectPositon"
              label="Positon"
              :items="positions"
              item-text="label"
              item-value="value"
              hide-details
              dense
              outlined
              @change="fetchReports(true)" />
            <v-select
              v-model="selectState"
              label="State"
              class="ml-2"
              :items="Object.keys(states)"
              hide-details
              dense
              outlined
              @change="fetchReports(true)" />
            <search-box
              v-model="searchInput"
              class="ml-2"
              @on-search="fetchReports(true)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items-per-page="-1"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูล"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          loading-text="กรุณารอสักครู่..."
          :expanded.sync="itemExpanded"
          show-expand
          @update:sort-by="fetchReports"
          @update:sort-desc="fetchReports">
          <template
            v-slot:expanded-item="{ headers: head, item }"
          >
            <td
              class="pa-4"
              :colspan="head.length">
              <h3>About</h3>
              <p>{{ item.about }}</p>
              <h3>Extra Meterial</h3>
              <p>
                <span
                  v-if="!!item.extraMeterial && isUrl(item.extraMeterial)"
                  class="open-link"
                  @click="downloadFile(item.extraMeterial)">
                  Open
                </span>
                <span v-else-if="!!item.extraMeterial">
                  {{ item.extraMeterial }}
                </span>
                <span v-else>
                  -
                </span>
              </p>
            </td>
          </template>
          <template #[`item.createdAt`]="{ item }">
            <span>
              {{ getDateFormat(item.createdAt) }}
            </span>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="text-left">
              {{ item.firstName }} {{ item.lastName }}
            </div>
          </template>
          <template #[`item.resume`]="{ item }">
            <v-btn
              icon
              small
              :disabled="!item.resume"
              @click="downloadFile(item.resume)">
              <v-icon small>
                mdi-file-outline
              </v-icon>
            </v-btn>
          </template>
          <!-- <template #[`item.social`]="{ item }">
            <span
              v-if="!!item.social && isUrl(item.social)"
              class="open-link"
              @click="downloadFile(item.social)">
              Open
            </span>
            <span v-else-if="!!item.social">
              {{ item.social }}
            </span>
            <span v-else>
              -
            </span>
          </template> -->
          <template #[`item.noticePeriod`]="{ item }">
            {{ item.noticePeriod }} วัน
          </template>
          <template #[`item.state`]="{ item }">
            <v-chip
              :color="states[item.state].color"
              small
              dark>
              {{ item.state }}
            </v-chip>
          </template>
          <template #[`item.option`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(state, index) of Object.keys(states).filter(s => s !== 'all')"
                  :key="index + '-state'"
                  @click="updateState(item, state)">
                  {{ state }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import { mapActions } from 'vuex'
import CareersProvider from '@/resources/CareerProvider'

const CareersService = new CareersProvider()
export default {
  components: { SearchBox },
  data () {
    return {
      states: {
        'all': {
          color: 'black'
        },
        'pending': {
          color: 'amber'
        },
        'waiting': {
          color: 'light-blue'
        },
        'Not answer': {
          color: 'pink'
        },
        'Interview#1': {
          color: 'teal'
        },
        'Interview#2': {
          color: 'teal darken-2'
        },
        'Interview#3': {
          color: 'teal darken-3'
        },
        'qualified': {
          color: 'green'
        },
        'not suitable': {
          color: 'grey'
        },
        'completed': {
          color: 'cyan darken-4'
        }
      },
      selectState: 'all',
      selectPositon: 'all',
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      sortBy: 'createdAt',
      sortDesc: true,
      positions: [],
      itemExpanded: [],
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          align: 'center',
          width: '150px'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'start'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          align: 'center'
        },
        // {
        //   text: 'Social Media',
        //   value: 'social',
        //   sortable: false,
        //   align: 'center'
        // },
        {
          text: 'Contact No.',
          value: 'contactNo',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Position',
          value: 'position',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Expected Salary',
          value: 'expectedSalary',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Notice Period',
          value: 'noticePeriod',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Resume',
          value: 'resume',
          sortable: false,
          align: 'center'
        },
        {
          text: 'State',
          value: 'state',
          align: 'center'
        },
        {
          text: 'Option',
          value: 'option',
          sortable: false,
          align: 'center'
        }
      ],
      reports: [],
      searchInput: ''
    }
  },
  watch: {
    page () {
      this.fetchReports()
    }
  },
  mounted () {
    this.fetchReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async updateState (item, state) {
      try {
        this.setLoading({ active: true })
        const { data } = await CareersService.updateState(item.id, {
          state
        })
        if (data) this.fetchReports()
      } catch (error) {
        console.error('fetchReports', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async fetchReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        if (isClear) {
          this.page = 1
        }

        const query = {
          page: this.page,
          limit: this.perPage,
          sortBy: this.sortBy,
          state: this.selectState,
          sortOrder: this.sortDesc ? 'desc' : 'asc',
          position: this.selectPositon,
          search: this.searchInput
        }

        const { data: count } = await CareersService.count(query)

        this.positions = count.map((c) => ({
          value: c.id,
          label: `${c.id} (${c.count})`
        }))

        const { data } = await CareersService.getCareers(query)

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = data.results
      } catch (error) {
        console.error('fetchReports', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    isUrl (url) {
      return /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/ig.test(url)
    },
    downloadFile (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
.open-link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}
</style>
