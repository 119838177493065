var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded elevation-3 bg-white h-full",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"header pl-2"},[_vm._v("Careers")])])],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"head-search ma-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"label":"Positon","items":_vm.positions,"item-text":"label","item-value":"value","hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.selectPositon),callback:function ($$v) {_vm.selectPositon=$$v},expression:"selectPositon"}}),_c('v-select',{staticClass:"ml-2",attrs:{"label":"State","items":Object.keys(_vm.states),"hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.selectState),callback:function ($$v) {_vm.selectState=$$v},expression:"selectState"}}),_c('search-box',{staticClass:"ml-2",on:{"on-search":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items-per-page":-1,"items":_vm.reports,"hide-default-footer":"","no-data-text":"ไม่มีข้อมูล","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"กรุณารอสักครู่...","expanded":_vm.itemExpanded,"show-expand":""},on:{"update:expanded":function($event){_vm.itemExpanded=$event},"update:sort-by":_vm.fetchReports,"update:sort-desc":_vm.fetchReports},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":head.length}},[_c('h3',[_vm._v("About")]),_c('p',[_vm._v(_vm._s(item.about))]),_c('h3',[_vm._v("Extra Meterial")]),_c('p',[(!!item.extraMeterial && _vm.isUrl(item.extraMeterial))?_c('span',{staticClass:"open-link",on:{"click":function($event){return _vm.downloadFile(item.extraMeterial)}}},[_vm._v(" Open ")]):(!!item.extraMeterial)?_c('span',[_vm._v(" "+_vm._s(item.extraMeterial)+" ")]):_c('span',[_vm._v(" - ")])])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateFormat(item.createdAt))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])]}},{key:"item.resume",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":!item.resume},on:{"click":function($event){return _vm.downloadFile(item.resume)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-outline ")])],1)]}},{key:"item.noticePeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.noticePeriod)+" วัน ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.states[item.state].color,"small":"","dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.option",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((Object.keys(_vm.states).filter(function (s) { return s !== 'all'; })),function(state,index){return _c('v-list-item',{key:index + '-state',on:{"click":function($event){return _vm.updateState(item, state)}}},[_vm._v(" "+_vm._s(state)+" ")])}),1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }